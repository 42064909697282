/* Imports */
import tippy from 'tippy.js';
import {hideAll} from 'tippy.js';

class Tooltips {
    constructor() {
        document.addEventListener('alpine:init', () => {

            // Magic: $tooltip
            Alpine.magic('tooltip', el => message => {
                let instance = tippy(el, {
                    allowHTML: false,
                    content: message,
                    interactive: true,
                    trigger: 'manual',
                });

                instance.show();

                setTimeout(() => {
                    instance.hide();

                    setTimeout(() => instance.destroy(), 150);
                }, 2000);
            });

            // Directive: x-tooltip
            Alpine.directive('tooltip', (el, { expression }) => {
                tippy(el, {
                    allowHTML: false,
                    content: expression,
                    delay: [500, 200],
                });
            });

            // Directive: x-popover
            Alpine.directive('popover', (el, { expression }) => {
                // This allows a template HTML to be passed into Tippy.
                // Add `data-template` with the ID of the section you wish to show within Tippy.
                // Example: <button x-data x-popover="menu">Open</button>
                tippy(el, {
                    allowHTML: true,
                    content(reference) {
                        const id = reference.getAttribute('x-popover');
                        const template = document.querySelector('[data-popover-id="'+id+'"]');

                        if (template) {
                            return template.innerHTML;
                        }
                        else {
                            console.warn('No popover template found.');
                            return null;
                        }
                    },
                    interactive: true,
                    placement: 'bottom',
                    theme: 'light-border',
                    trigger: 'click',
                    onShown: function(instance) {
                        const popoverTemplate = instance.reference ? instance.reference.getAttribute('x-popover') : null;

                        // Check for Sharethis
                        if (popoverTemplate === 'story-share-popover') {
                            if (window.__sharethis__) {
                                // Reinit Sharethis
                                window.__sharethis__.initialize();
                            }
                        }
                    }
                });
            });

            // Magic: $popoverHideAll
            Alpine.magic('popoverHideAll', el => message => {
                // Hide all tippy instances instantly
                hideAll({ duration: 0 });
            });

        });
    }
}

export default new Tooltips();
